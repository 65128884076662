module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"sajdahblog","schemas":{"post":{"Main":{"uid":{"type":"UID","config":{"label":"UID"}},"category":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Category"}},"date":{"type":"Date","config":{"label":"Date"}},"post_title":{"type":"StructuredText","config":{"single":"heading1","label":"Post Title","placeholder":"Title"}},"featured_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Featured Image"}},"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":{},"choices":{"text":{"type":"Slice","fieldset":"Text","description":"Rich Text Section","icon":"text_fields","non-repeat":{"text":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"text","placeholder":"Your content here"}}},"repeat":{}},"quote":{"type":"Slice","fieldset":"Quote","description":"A short quote with author's portrait","icon":"add_alert","non-repeat":{"quote":{"type":"StructuredText","config":{"single":"em","label":"Quote","placeholder":"Your quote"}},"portrait_author":{"type":"Image","config":{"constraint":{"width":700,"height":700},"thumbnails":[{"name":"mobile","width":350,"height":350}],"label":"Portrait Author"}}},"repeat":{}}}}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-185},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sajdah","short_name":"Sajdah","start_url":"/","background_color":"#ffffff","theme_color":"#009196","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff543fdb1fb8cddb348e11265d9eee40"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"19807131","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"3203395393079935"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-159417351-1","G-TVPPR2S3PQ"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5HXGV4J","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
